/* Toaster */
.toasts-container {
    position: fixed;
    padding: 1rem;
    right: 0;
    z-index: 1000;
}

.toasts-container--empty {
    display: none;
}

.toasts-container-top-right {
    top: 0;
}

.toasts-container-bottom-right {
    bottom: 0;
}

.toast--top-right {
    margin-bottom: 1rem;
}

.toast--bottom-right {
    margin-top: 1rem;
}

.toast {
    opacity: 0.5;
    transition: opacity 0.5s ease-in-out;
}

.toast-slice {
    background: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: 3px;
    font-size: 0.88rem;
    color: var(--color-gray-1);
    width: 25rem;
    border-left: 3px solid var(--color-emphasis);
    padding: 0.6rem;
    display: flex;
}

.toast__ribbon {
    width: 3px;
    background: red;
}

.toast__title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
}

.toast__icon {
    margin-right: 0.6rem;
}

.toast__icon--info, .toast-slice--info {
    fill: var(--color-primary);
    border-left-color: var(--color-primary);
}

.toast__icon--success, .toast-slice--success {
    fill: var(--color-success);
    border-left-color: var(--color-success);
}

.toast__icon--warning, .toast-slice--warning {
    fill: var(--color-warning);
    border-left-color: var(--color-warning);
}

.toast__icon--error, .toast-slice--error {
    fill: var(--color-error);
    border-left-color: var(--color-error);
}

.toast__close {
    cursor: pointer;
    margin: 0 0 auto auto;
}

.toast__close .icon-close {
    fill: var(--color-white);
}

.toast__close .icon-close:hover {
    fill: var(--color-primary);
}
