.last-ad__sorting {
    width: 15rem;
}

.last-ad__images {
    display: flex;
    overflow: auto;
}

.last-ad img {
    width: 100px;
    margin-right: 2px;
}

.last-ad__image-delete {
    position: absolute;
    width: 1.8rem;
    height: 1.8rem;
    margin-top: -1.8rem;
    cursor: pointer;
}

.last-ad__image-delete svg {
    fill: transparent;
}

.last-ad__image:hover svg {
    fill: var(--color-error);
}

.last-ad .input, .last-ad .checkbox {
    margin: 1rem 0;
}

.last-ad__info {
    display: flex;
}

.last-ad__info > div {
    flex: 1;
    padding-right: 1rem;
}

.last-ad__info > div:nth-of-type(2) {
    margin-right: auto;
}

.last-ad__created-at {
    font-size: 0.8rem;
    margin-left: 1rem;
}

.last-ad pre {
    white-space: normal;
}

.last-ad__ext-link {
    color: #fff;
    text-decoration: none;
    font-size: 0.8rem;
}

.last-ad__ext-link:hover {
    color: var(--color-emphasis);
}
