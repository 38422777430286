.checkbox {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    cursor: pointer;
    user-select: none;
}

.checkbox__input {
    display: inline-flex;
    align-items: center;
}

.checkbox__input input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
}

.checkbox__control {
    width: 1rem;
    height: 1rem;
    border-radius: 3px;
    border: 1px solid var(--color-white);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.25rem;
}

.checkbox__control svg {
    display: none;
    width: 0.63rem;
}

/* checked */

.checkbox__input input:checked + .checkbox__control svg {
    display: block;
    stroke: var(--color-gray-1);
}

.checkbox__input input:checked + .checkbox__control {
    border-color: var(--color-primary);
    background: var(--color-primary);
}
