.modal-bottom {
    margin: var(--pad) 0 0; /* consider using "auto" for top margin */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: calc(2 * var(--pad));
}

.modal-bottom .button {
    margin-left: 0.5rem;
}

@media (max-width: 414px) {
    .modal-bottom {
        height: 2.2rem;
    }
}
