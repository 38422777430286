.row {
    display: flex;
}

.row.center {
    align-items: center;
}

.row.bottom {
    align-items: flex-end;
}

@media (max-width: 1024px) {
    .row {
        flex-direction: column;
    }
}
