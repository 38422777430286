.merge-ads {
    display: flex;
    width: 100%;
    height: 100%;
}

.merge-ads > div {
    margin-right: 1rem;
    flex: 1;
    width: 33%;
    height: 98%;
    overflow-y: auto;
}

.merge-ads pre {
    overflow-x: auto;
    white-space: pre-wrap;
}

.merge-ads textarea {
    margin-top: 8px;
    width: 100%;
    height: 100%;
    white-space: pre-wrap;
}
