.nav-button {
    max-width: 3rem;
    max-height: 3rem;
    min-width: 3rem;
    min-height: 3rem;
    background: var(--color-gray-1);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
}

.nav-button:hover {
    background: var(--color-gray-3);
}

.nav-button:hover svg {
    fill: var(--color-primary);
}

.nav-button--tinted {
    fill: rgba(255, 255, 255, 0.8);
}
