.page-home__no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-size: 2.5rem;
    font-weight: 300;
    flex-direction: column;
    padding-bottom: 4rem;
    text-align: center;
}

.page-home__no-results__img {
    background-image: url('no-results-airplane.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 20vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-bottom: 26.92%;
    margin-top: 3rem;
}

.page-home__no-results__hint {
    font-size: 0.8rem;
    margin-top: 0.5rem;
}

/**/

@media (max-width: 414px) {
    .page-home__no-results {
        font-size: 2rem;
        line-height: 110%;
    }
    .page-home__no-results__img {
        margin-top: 1rem;
    }
}
