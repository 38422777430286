footer {
    width: 100%;
    font-size: 0.8rem;
    margin: auto 0 0 0;
    padding: 1rem;
    background: var(--color-gray-1);
}

footer a {
    color: var(--color-emphasis);
    margin-right: 0.5rem;
}
