.page-airfield {
    padding-top: 1rem;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 400;
}

.page-airfield .top-navigator {
    margin-bottom: 0.5rem;
    padding: 0 1rem;
}

.page-airfield__container {
    padding: 0 1rem;
}

.page-airfield h1 {
    margin: 0 0 1rem;
}

.page-airfield a {
    text-decoration: none;
    color: var(--color-primary);
}

.page-airfield .mapboxgl-map {
    margin-bottom: 1rem;
}

.page-airfield__map {
    width: calc(100vw - 2rem);
    height: calc(100vh - 420px);
}

.page-airfield__suggestions {
    font-size: 1.5rem;
}

.page-airfield__article {
    max-width: 640px;
}

@media (max-width: 414px) {
    .page-airfield h1 {
        font-size: 1.25rem;
    }
}
