.input {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.9rem;
}

.input span {
    margin-bottom: 0.1rem;
}

.input input {
    background: transparent;
    border: none;
    outline: none;
    font: 500 1rem 'Open Sans';
    padding: 0.4rem 0.6rem;
    color: var(--color-white);
    width: 100%;
}

.input__row {
    display: flex;
    border: 1px solid #888;
    border-radius: 0.25rem;
    height: 2.3rem;
}

.input__right {
    height: 2.3rem;
}

/**/

.input--error span {
    color: var(--color-error);
}

.input--error input {
    color: var(--color-error);
    border-color: var(--color-error);
}

.input--error input::placeholder {
    color: var(--color-error);
}
