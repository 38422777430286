.feed-item {
    width: 20.25rem;
    border-radius: 0.75rem;
    overflow: hidden;
    background: var(--color-gray-1);
    cursor: pointer;
    display: block;
}

.feed-item {
    text-decoration: none;
}

.feed-item:hover {
    background: var(--color-gray-3);
}

.feed-item img {
    border-radius: 0.75rem 0.75rem 0 0;
    width: 20.25rem;
    height: 12.5rem;
    object-fit: cover;
    display: block;
}

.feed-item__bottom {
    padding: 0.5rem 0.5rem 0.7rem 1rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
    font-weight: 500;
    color: var(--color-white);
}

.feed-item__price, .feed-item__distance {
    position: relative;
    font-size: 0.9rem;
    background: var(--color-gray-1t);
    font-weight: 500;
    /* max contrast needed */
    color: #fff;
    border-radius: 0.25rem;
    padding: 0.1rem 0.3rem 0.15rem;
}

.feed-item__price {
    margin: -1.7rem auto 0.5rem 0.25rem;
    float: left;
 }

.feed-item__price span {
    font-size: 70%;
}

.feed-item__distance {
    margin: -12.3rem 0.25rem 11rem auto;
    float: right;
}

.feed-item:hover .feed-item__bottom {
    text-decoration: underline;
    color: var(--color-emphasis);
}

@media (max-width: 414px) {

}
