.select {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.9rem;
}

.select__ul {
    padding: 0;
    margin: 0;
    max-height: 13.8rem;
    overflow-y: auto;
    background: var(--color-gray-3);
    border-radius: 0.25rem;
    scroll-snap-type: y mandatory;
    box-shadow: 0 0 5px 0 rgba(255,255,255,0.2);
}

.select__li {
    list-style: none;
    margin: 0;
    height: 2.3rem;
    cursor: pointer;
    scroll-snap-align: center;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    width: 15rem;
}

.select__li:hover {
    background: var(--color-gray-4);
}

.select__li span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.select__opener {
    height: 100%;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
