.login-home {
    padding: 1rem;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-home form {
    background: var(--color-white);
    border-radius: 2rem;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.login-home input {
    border: 1px solid var(--color-gray-4);
    outline: none;
    font: 500 1rem 'Open Sans';
    border-radius: 0.25rem;
    margin-bottom: 2rem;
    padding: 0.4rem 0.6rem;
}

.login-home button {
    background: var(--color-primary);
    border: none;
    font: 500 1.2rem 'Open Sans';
    cursor: pointer;
    border-radius: 0.25rem;
    padding: 0.25rem 0.6rem;
}

.login-home button:hover {
    filter: brightness(103%);
}
