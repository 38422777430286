.page-item {
    padding-top: 1rem;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 400;
}

.page-item__content {
    display: flex;
    flex-direction: row;
    padding: 0 2rem 2rem;
}

.page-item__images {
    border-radius: 1rem;
    overflow: hidden;
    max-width: 800px;
    margin-right: 2rem;
}

.page-item__main-image {
    width: 100%;
    max-height: 600px;
    object-fit: cover;
    display: flex;
}

.page-item__main-image--big {
    cursor: zoom-in;
}

.page-item__aux-images {
    width: 100%;
    max-height: 15%;
    overflow-x: auto;
    display: flex;
    scroll-snap-type: x proximity;
    -webkit-overflow-scrolling: touch;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.page-item__aux-image {
    min-width: calc(100% / 4);
    max-height: 15%;
    object-fit: cover;
    scroll-snap-align: start;
    cursor: pointer;
    filter: brightness(70%);
}

.page-item__aux-image:hover {
    filter: none;
}

.page-item__header {
    margin: 0 0 0.9rem;
    font-weight: 400;
    line-height: 110%;
}

.page-item__header span {
    opacity: 0.5;
}

.page-item__description {
    max-width: calc(100vw - 6rem - 800px);
    font-size: 0.9rem;
    white-space: pre-line;
    line-height: 130%;
    margin-top: 0.5rem;
}

.page-item__price {
    font-size: 2rem;
    color: var(--color-emphasis);
    line-height: 1.5rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.page-item__price span {
    font-size: 70%;
}

.page-item__content a {
    text-decoration: none;
    color: var(--color-primary);
}

.page-item__content a:hover {
    text-decoration: underline;
}

.page-item h2 {
    font-size: 1.25rem;
    font-weight: 400;
    color: var(--color-emphasis);
    margin: 1rem 0 0.2rem;
}

.page-item__report {
    font-size: 0.8rem;
    margin-top: 0.5rem;
}

.form-feedback, .form-vat {
    min-width: 600px;
}

/**/

@media (max-width: 1200px) {
    .page-item__header {
        margin-top: 1rem;
    }

    .page-item {
        padding: 1rem 0;
    }

    .page-item__content {
        flex-direction: column;
        padding: 0 1rem;
    }

    .page-item__description {
        max-width: unset;
    }

    .page-item__images {
        margin-right: 0;
    }
}

@media (max-width: 414px) {
    .page-item__header {
        font-size: 2rem;
        margin-top: 1rem;
    }

    .page-item__price {
        font-size: 1.5rem;
    }

    .form-feedback, .form-vat {
        min-width: 360px;
    }
}
