.top-navigator {
    margin-bottom: 1rem;
    width: 100%;
    padding: 0 2rem;
}

.top-navigator .nav-button {
    margin-right: 0.5rem;
}

/**/

@media (max-width: 1200px) {
    .top-navigator {
        padding: 0 1rem;
        position: sticky;
        top: 0;
    }
}
