.button {
    background: var(--color-primary);
    border: none;
    font: 500 1rem 'Open Sans';
    cursor: pointer;
    border-radius: 0.25rem;
    padding: 0.25rem 0.6rem;
    height: 2.3rem;
}

.button:hover {
    filter: brightness(103%);
    color: var(--color-gray-1);
}

.button:disabled {
    background: var(--color-gray-4);
}

/**/

.input + .button {
    margin-left: 0.5rem;
}

.button--skin-secondary {
    background: var(--color-gray-4);
    color: var(--color-white);
}

.button--skin-secondary:hover {
    filter: brightness(103%);
    color: var(--color-white);
}

.button--skin-danger {
    background: var(--color-gray-4);
    color: var(--color-warning);
}

.button--skin-danger:hover {
    filter: brightness(103%);
    color: var(--color-warning);
}

@media (pointer: coarse) {
    .button {
        height: 2.75rem;
        min-width: 2.75rem;
    }
}
