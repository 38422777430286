.admin-home {
    display: flex;
    height: 100%;
    position: fixed;
    top: 0;
    max-width: 100vw;
}

.admin-home__menu {
    margin: 1rem;
    padding: 0;
    min-width: 8rem;
}

.admin-home__menu li {
    list-style: none;
    cursor: pointer;
}

.admin-home__menu li:hover {
    color: #ddd;
}

.admin-home__menu li.selected {
    color: var(--color-emphasis);
}

.admin-home__menu a {
    color: unset;
    text-decoration: none;
}

.admin-home__right {
    padding: 1rem;
    width: calc(100% - 8rem);
    height: 100%;
    overflow-y: auto;
}

.admin-home fieldset {
    border: 1px solid var(--color-gray-4);
    border-radius: 0.25rem;
    padding: 1rem;
}

.admin-home fieldset .select, .admin-home fieldset .input {
    margin-right: 1rem;
}

.admin-home fieldset.column {
    width: 19rem;
}

.admin-home fieldset.column .select, .admin-home fieldset.column .input, .admin-home fieldset.column .checkbox {
    margin-bottom: 1rem;
}

.admin-home fieldset.row {
    display: flex;
    align-items: center;
}
