.textarea {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.9rem;
}

.textarea span {
    margin-bottom: 0.1rem;
}

.textarea textarea {
    background: transparent;
    border: none;
    outline: none;
    font: 500 1rem 'Open Sans';
    padding: 0.4rem 0.6rem;
    color: var(--color-white);
    width: 100%;
}

.textarea__row {
    display: flex;
    border: 1px solid #888;
    border-radius: 0.25rem;
}

/**/

.textarea--error span {
    color: var(--color-error);
}

.textarea--error textarea {
    color: var(--color-error);
    border-color: var(--color-error);
}

.textarea--error textarea::placeholder {
    color: var(--color-error);
}
