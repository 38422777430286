.page-home {
    padding-top: 2rem;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    font-weight: 300;
    align-items: center;
}

.page-home__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 2rem 2rem;
    align-items: center;
}

.page-home__search-bar {
    padding: 0 2rem;
    width: 100%;
    position: sticky;
    top: 0;
    flex-direction: column;
    z-index: 1;
}

.page-home__search {
    background: var(--color-gray-1);
    padding: 0 1.2rem;
    border-radius: 1rem;
    display: flex;
    margin-bottom: 2rem;
    align-items: center;
}

.page-home__search input {
    flex: 1;
    background: transparent;
    color: var(--color-white);
    font: 300 2rem 'Open Sans';
    border: 0;
    outline: 0;
    margin: 0.8rem 0;
    width: 100%;
}

.page-home__feed {
    width: 100%;
    display: grid;
    gap: 2.5rem 2rem;
    grid-template-columns: repeat(auto-fit, minmax(20.25rem, 1fr));
    justify-items: center;
}

.page-home__feed--micro {
    grid-template-columns: repeat(auto-fit, 20.25rem);
}

.page-home__pagination {
    display: block;
    margin-top: 2rem;
}

.page-home__pagination ul {
    margin: 0;
    padding: 0;
}

.page-home__pagination li {
    list-style: none;
    display: inline-flex;
}

.page-home__pagination a {
    display: inline-flex;
    background: var(--color-gray-3);
    border-radius: 0.5rem;
    padding: 4px 8px;
    margin: 0 4px;
    width: 3rem;
    font-size: 1rem;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 2rem;
    color: var(--color-white);
    text-decoration: none;
}

.page-home__pagination a.active {
    background: var(--color-primary);
    color: var(--color-gray-1);
}

.page-home__pagination a:hover {
    background: var(--color-gray-4);
    color: var(--color-white);
}

.page-home__info-section {
    height: 1px;
    overflow: hidden;
    font-size: 1rem;
    padding: 0 2rem;
}

.page-home__info-section--open {
    height: auto;
    margin: -1.5rem 0 1rem;
}

.page-home h1 {
    font: 400 1.5rem 'Open Sans';
}

.page-home .rotating-earth {
    animation: rotate 1s linear infinite, glow 1s ease-in-out infinite;
}

/**/

@media (max-width: 414px) {
    .page-home {
        padding: 0;
        overflow-x: hidden;
        max-height: 100vh;
    }

    .page-home__content {
        padding-bottom: 0;
    }

    .page-home__search-bar {
        width: 100%;
        padding: 1rem;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 85%, rgba(0, 0, 0, 0) 100%);
        display: flex;
        justify-content: center;
    }

    .page-home__search {
        padding: 0 0 0 0.6rem;
        margin-bottom: 0;
        width: 100%;
    }

    .page-home__search input {
        font-size: 1.5rem;
        margin: 0.4rem 0;
    }

    .page-home__pagination {
        margin-top: 1rem;
        padding-bottom: 1rem;
    }
}
