#modal-background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: var(--color-gray-2);
    z-index: 10;
    opacity: 0.5;
}

.modal {
    --pad: 1.63rem;
    /* a hack to get the padding variable into css translate */
    --minus-pad: -1.63rem;
    z-index: 12;
    position: fixed;
    background: var(--color-gray-3);
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.25);
    color: #fff;
    display: flex;
    flex-direction: column;
    padding-bottom: var(--pad);
    top: 50%;
    left: 50%;
    border-radius: 3px;
    max-height: 100vh;
    max-width: 100vw;
}

.modal--standalone {
    max-height: 95vh;
}

.modal--untouched {
    transform: translate(-50%, -50%);
}

.modal__header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4rem;
    min-height: 4rem;
    padding-left: var(--pad);
    justify-content: space-between;
    border-bottom: 1px solid var(--color-gray-4);
}

.modal__header--movable {
    cursor: grabbing;
}

.modal__header-inner {
    font-size: 1.13rem;
    font-weight: 400;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    max-height: calc(100% - 4rem);
    overflow-y: auto;
}

.modal__content--padded {
    padding: var(--pad) var(--pad) 1px;
}

.modal__close {
    cursor: pointer;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Movement related */

.modal__shadow {
    will-change: transform, width, height;
    position: fixed;
    z-index: 14;
    background: repeating-linear-gradient(
        135deg,
        rgba(255, 255, 255, .3),
        rgba(255, 255, 255, .3) 10px,
        rgba(255, 255, 255, .5) 10px,
        rgba(255, 255, 255, .5) 15px
    );
    border: 5px dashed #fff;
    display: none;
    cursor: move;
}

.modal__movement-assistant {
    display: none;
    z-index: 13;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
}

/* Resizing related */

.modal__inside-resizable {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
}

.modal__vertical-box {
    display: flex;
    flex-direction: column;

    /* Auxiliary restriction due to position:fixed of the modal root */
    max-height: calc(100vh - 4rem);
}

.modal--standalone .modal__vertical-box {
    max-height: calc(95vh - 4rem);
}

.modal__horizontal-box {
    display: flex;
    flex-direction: row;

    /* Auxiliary restriction due to position:fixed of the modal root */
    max-height: calc(100vh - 4rem);
}

.modal--standalone .modal__horizontal-box {
    max-height: calc(95vh - 4rem);
}

.modal__border {
    background: transparent;
    position: absolute;
}

.modal__border-top, .modal__border-bottom {
    width: 100%;
    height: 5px;
    cursor: row-resize;
}

.modal__border-left, .modal__border-right {
    height: 100%;
    width: 5px;
    cursor: col-resize;
}

.modal__border-right {
    right: 0;
}

.modal__border-bottom {
    bottom: 0;
}

@media (max-width: 414px) {
    .modal {
        --pad: 1rem;
    }

    .modal.modal--fs-mobile {
        width: 100vw;
        height: 100%;
        max-height: unset;
    }
}
